import { useEffect, useRef, useState } from "react"
import { apiListAnnouncements } from "../../../functions/api/announcement"
import { Announcement } from "../../../types"
import { ANNOUNCEMENT_ID, ANNOUNCEMENT_LIST_LIMIT } from "../../../values"

const useAnnouncement = (isPc: boolean) => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([])
  const [shouldScroll, setShouldScroll] = useState<boolean>(true)
  const [nextToken, setNextToken] = useState<string | null | undefined>(null)

  const announcementContainer = useRef<HTMLDivElement>(null)
  const mobileAnnouncementContainer = useRef<HTMLDivElement>(null)

  const onScroll = () => {
    if (isPc) {
      if (
        announcementContainer.current &&
        announcementContainer.current.scrollHeight - announcementContainer.current.scrollTop ==
          announcementContainer.current.clientHeight
      ) {
        if (nextToken) {
          setShouldScroll(true)
        }
      }
    } else {
      if (
        mobileAnnouncementContainer.current &&
        mobileAnnouncementContainer.current.scrollWidth -
          mobileAnnouncementContainer.current.scrollLeft ==
          mobileAnnouncementContainer.current.clientWidth
      ) {
        if (nextToken) {
          setShouldScroll(true)
        }
      }
    }
  }

  useEffect(() => {
    announcementContainer.current?.addEventListener("scroll", onScroll)
    mobileAnnouncementContainer.current?.addEventListener("scroll", onScroll)

    return () => {
      announcementContainer.current?.removeEventListener("scroll", onScroll)
      mobileAnnouncementContainer.current?.removeEventListener("scroll", onScroll)
    }
  }, [isPc, shouldScroll, nextToken])

  useEffect(() => {
    ;(async () => {
      if (shouldScroll) {
        const response = await apiListAnnouncements(
          ANNOUNCEMENT_ID,
          undefined,
          undefined,
          50,
          nextToken || undefined
        )
        if (response) {
          setAnnouncements(a => {
            return a.concat(response!.items)
          })
          setNextToken(response.nextToken)
          setShouldScroll(false)
        }
      }
    })()
  }, [shouldScroll])

  return {
    data: announcements,
    ref: announcementContainer,
    moblieRef: mobileAnnouncementContainer,
  }
}

export default useAnnouncement
