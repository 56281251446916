import { useEffect, useState } from "react"
import { ProductStatus } from "../../../API"
import { apiListProducts } from "../../../functions/api/product"
import { Product } from "../../../types"

const useSearchedProduct = () => {
  const [search, setSearch] = useState<TemplateStringsArray>()

  const [searchedText, setSearchedText] = useState<string>("")
  const [searchedProducts, setSearchedProducts] = useState<Product[]>([])
  const [shouldScroll, setShouldScroll] = useState<boolean>(true)
  const [nextToken, setNextToken] = useState<string | null | undefined>(null)

  const onScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (nextToken) {
        setShouldScroll(true)
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [shouldScroll, nextToken])

  useEffect(() => {
    ;(async () => {
      if (shouldScroll) {
        const savedSearchText = window.localStorage.getItem("searchText")

        const response = await apiListProducts(
          {
            name: {
              contains: savedSearchText,
            },
            // status: {
            //   ne: ProductStatus.INACTIVE,
            // },
          },
          3000
        )

        if (response) {
          setSearchedProducts(response.items)
          setSearchedText(savedSearchText || "")
        }

        setShouldScroll(false)
      }
    })()
  }, [search, shouldScroll])

  return {
    data: searchedProducts,
    searchedText,
    setSearchedText,
    setSearch,
    setShouldScroll,
  }
}

export default useSearchedProduct
