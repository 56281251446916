/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_appsync_graphqlEndpoint": "https://v26uqtvlarakzbsoejizwee44q.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-nccy5mqt7bgunfsycc54cynmfi",
    "aws_cloud_logic_custom": [
        {
            "name": "callmartstorage",
            "endpoint": "https://lphkwdme5k.execute-api.ap-northeast-2.amazonaws.com/prod",
            "region": "ap-northeast-2"
        }
    ]
};


export default awsmobile;
