import { API, graphqlOperation } from "aws-amplify"

import { handleError, handleSuccess } from ".."
import {
  CreateDeliveryInput,
  CreateDeliveryMutation,
  DeleteDeliveryInput,
  DeleteDeliveryMutation,
  DeliverysByCustomerOrderDateQuery,
  DeliverysByOrderDateQuery,
  GetDeliveryQuery,
  ListDeliverysQuery,
  ModelDeliveryConditionInput,
  ModelDeliveryFilterInput,
  ModelSortDirection,
  ModelStringKeyConditionInput,
  UpdateDeliveryInput,
  UpdateDeliveryMutation,
} from "../../API"

import * as mutations from "../../graphql/mutations"
import * as queries from "../../graphql/queries"

import { Delivery, DeliveryStatus } from "../../types"

export const apiCreateDelivery = async (input: CreateDeliveryInput) => {
  try {
    /*
    const response = (await API.graphql(
      graphqlOperation(mutations.createDelivery, { input, condition })
    )) as { data: CreateDeliveryMutation }
    handleSuccess(response)
    return (response.data.createDelivery as unknown) as Delivery
    */
    const path = `/delivery`
    const params = {
      headers: {},
      queryStringParameters: {},
      body: input,
    }

    const response = await API.post("callmartstorage", path, params)
    return response as Delivery
  } catch (error) {
    handleError(error)
  }
}

export const apiUpdateDelivery = async (
  input: UpdateDeliveryInput,
  condition?: ModelDeliveryConditionInput
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(mutations.updateDelivery, { input, condition })
    )) as { data: UpdateDeliveryMutation }
    handleSuccess(response)
    return (response.data.updateDelivery as unknown) as Delivery
  } catch (error) {
    handleError(error)
  }
}

export const apiDeleteDelivery = async (
  input: DeleteDeliveryInput,
  condition?: ModelDeliveryConditionInput
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(mutations.deleteDelivery, { input, condition })
    )) as { data: DeleteDeliveryMutation }
    handleSuccess(response)
    return (response.data.deleteDelivery as unknown) as Delivery
  } catch (error) {
    handleError(error)
  }
}

const convertStatusToType = (status?: string) => {
  switch (status) {
    case "ORDERED":
      return DeliveryStatus.ORDERED
    case "DELIVERED":
      return DeliveryStatus.DELIVERED
    case "OUT_FOR_DELIVERY":
      return DeliveryStatus.OUT_FOR_DELIVERY
    case "CANCELLED":
      return DeliveryStatus.CANCELLED
    default:
      return null
  }
}

export const apiGetDelivery = async (id: String) => {
  try {
    const response = (await API.graphql(graphqlOperation(queries.getDelivery, { id }))) as {
      data: GetDeliveryQuery
    }
    handleSuccess(response)
    return response.data.getDelivery
      ? (({
          ...response.data.getDelivery,
          items:
            response.data.getDelivery?.items &&
            response.data.getDelivery?.items.map(i => i && JSON.parse(i)),
          status: response.data.getDelivery?.status,
        } as unknown) as Delivery)
      : null
  } catch (error) {
    handleError(error)
  }
}

export const apiListDeliverys = async (
  filter?: ModelDeliveryFilterInput,
  limit?: Number,
  nextToken?: String
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(queries.listDeliverys, { filter, limit, nextToken })
    )) as { data: ListDeliverysQuery }
    handleSuccess(response)
    return (response.data.listDeliverys?.items?.map(item => ({
      ...item,
      items: item?.items,
      status: convertStatusToType(item?.status),
    })) as unknown) as Delivery[]
  } catch (error) {
    handleError(error)
  }
}

export const apiGetDeliverysForCustomer = async (
  customerId: string | undefined,
  orderDateTime?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelDeliveryFilterInput,
  limit?: number,
  nextToken?: String
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(queries.deliverysByCustomerOrderDate, {
        customerId,
        orderDateTime,
        sortDirection,
        filter,
        limit,
        nextToken,
      })
    )) as { data: DeliverysByCustomerOrderDateQuery }
    //console.log(response)
    return (response.data.deliverysByCustomerOrderDate?.items?.map(item => ({
      ...item,
      items: item?.items,
      status: convertStatusToType(item?.status),
    })) as unknown) as Delivery[]
  } catch (error) {
    handleError(error)
  }
}
