import { API, graphqlOperation } from "aws-amplify"

import { handleError, handleSuccess } from ".."
import * as queries from "../../graphql/queries"
import * as mutations from "../../graphql/mutations"

import { STORE_HOUR_ID } from "../../values"
import { StoreHour } from "../../types"
import { UpdateStoreHourInput } from "../../API"

export async function apiGetStoreHour() {
  try {
    const response = (await API.graphql(
      graphqlOperation(queries.getStoreHour, { id: STORE_HOUR_ID })
    )) as { data: { getStoreHour: StoreHour } }
    handleSuccess(response)
    return response.data.getStoreHour
  } catch (error) {
    handleError(error)
  }
}