import Amplify from "aws-amplify"
import React, { createContext } from "react"
import useDeliveryReducer from "./hooks/useDeliveryReducer"
import useSearchBarReducer from "./hooks/useSearchBarReducer"
import awsConfig from "../../aws-exports"
import { useMediaQuery } from "react-responsive"
import { MEDIA_QUERY } from "../../values"
import useAddConformModal from "./hooks/useAddConfirmModal"
import useStoreHour from "./hooks/useStoreHour"
import useEvent from "./hooks/useEvent"
import useSearchedProduct from "../IndexContext/hooks/useSearchedProduct"
import useAnnouncement from "../IndexContext/hooks/useAnnouncement"
import useCategory from "../IndexContext/hooks/useCategory"
import useCategoryProduct from "../CategoryProductContext/hooks/useCategoryProduct"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"

Amplify.configure(awsConfig)

interface ContextValueType {
  searchBarReducer: ReturnType<typeof useSearchBarReducer>
  deliveryReducer: ReturnType<typeof useDeliveryReducer>
  addConfirmModalHook: ReturnType<typeof useAddConformModal>
  storeHourHook: ReturnType<typeof useStoreHour>
  eventHook: ReturnType<typeof useEvent>
  searchHook: ReturnType<typeof useSearchedProduct>
  announcementHook: ReturnType<typeof useAnnouncement>
  categoryHook: ReturnType<typeof useCategory>
  categoryProducts: ReturnType<typeof useCategoryProduct>
  isPc: boolean
}

export const globalContext = createContext<ContextValueType | null>(null)

const GlobalContextProvider = ({ children }: { children: React.ReactNode }) => {
  const searchBarReducer = useSearchBarReducer()
  const addConfirmModalHook = useAddConformModal()
  const storeHourHook = useStoreHour()
  const deliveryReducer = useDeliveryReducer(storeHourHook.isDeliverable)
  const eventHook = useEvent()
  const searchHook = useSearchedProduct()
  const categoryHook = useCategory()
  const categoryProducts = useCategoryProduct()

  const isPc = useMediaQuery({
    query: MEDIA_QUERY,
  })

  const announcementHook = useAnnouncement(isPc)

  const value: ContextValueType = {
    searchBarReducer,
    deliveryReducer,
    addConfirmModalHook,
    storeHourHook,
    eventHook,
    searchHook,
    announcementHook,
    categoryHook,
    isPc,
    categoryProducts
  }

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#b53843'
      }
    }
  })

  return (
      <MuiThemeProvider theme={theme}>
    <globalContext.Provider value={value}>
        {children}
    </globalContext.Provider>
      </MuiThemeProvider>
  )
}

export default GlobalContextProvider
