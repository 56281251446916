import { API, graphqlOperation } from "aws-amplify"

import { handleError, handleSuccess } from ".."
import {
  CreateAnnouncementInput,
  CreateAnnouncementMutation,
  DeleteAnnouncementInput,
  DeleteAnnouncementMutation,
  GetAnnouncementQuery,
  ListAnnouncementsQuery,
  ModelAnnouncementConditionInput,
  ModelAnnouncementFilterInput,
  ModelSortDirection,
  ModelStringKeyConditionInput,
  UpdateAnnouncementInput,
  UpdateAnnouncementMutation,
} from "../../API"

import * as mutations from "../../graphql/mutations"
import * as queries from "../../graphql/queries"

import { Announcement } from "../../types"

export async function apiCreateAnnouncement(
  input: CreateAnnouncementInput,
  condition?: ModelAnnouncementConditionInput
) {
  try {
    const response = (await API.graphql(
      graphqlOperation(mutations.createAnnouncement, { input, condition })
    )) as { data: CreateAnnouncementMutation }
    handleSuccess(response)
    return (response.data.createAnnouncement as unknown) as Announcement
  } catch (error) {
    //handleError("create_announcement", error);
    handleError(error)
  }
}

export const apiUpdateAnnouncement = async (
  input: UpdateAnnouncementInput,
  condition?: ModelAnnouncementConditionInput
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(mutations.updateAnnouncement, { input, condition })
    )) as { data: UpdateAnnouncementMutation }
    handleSuccess(response)
    return (response.data.updateAnnouncement as unknown) as Announcement
  } catch (error) {
    //handleError(new Error"update_announcement", error);
    handleError(error)
  }
}

export const apiDeleteAnnouncement = async (
  input: DeleteAnnouncementInput,
  condition?: ModelAnnouncementConditionInput
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(mutations.deleteAnnouncement, { input, condition })
    )) as { data: DeleteAnnouncementMutation }
    handleSuccess(response)
    return (response.data.deleteAnnouncement as unknown) as Announcement
  } catch (error) {
    //handleError("delete_announcement", error);
    handleError(error)
  }
}

export const apiGetAnnouncement = async (id: String) => {
  try {
    const response = (await API.graphql(graphqlOperation(queries.getAnnouncement, { id }))) as {
      data: GetAnnouncementQuery
    }
    handleSuccess(response)
    return (response.data.getAnnouncement as unknown) as Announcement
  } catch (error) {
    // handleError("get_announcement", error);
    handleError(error)
  }
}

export const apiListAnnouncements = async (
  id: string,
  date?: ModelStringKeyConditionInput,
  filter?: ModelAnnouncementFilterInput,
  limit?: number,
  nextToken?: string
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(queries.listAnnouncements, {
        id,
        date,
        filter,
        limit,
        nextToken,
        sortDirection: ModelSortDirection.DESC,
      })
    )) as { data: ListAnnouncementsQuery }
    handleSuccess(response)
    return {
      items: (response.data.listAnnouncements?.items as unknown) as Announcement[],
      nextToken: response.data.listAnnouncements?.nextToken,
    }
  } catch (error) {
    // handleError("list_announcement", error);
    handleError(error)
  }
}
