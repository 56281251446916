import publicIp from "public-ip"
import { useEffect, useState } from "react"
import { DeliveryStatus, PaymentType } from "../../../API"
import { handleError, handleSuccess, numberWithCommas } from "../../../functions"
import { apiCreateDelivery } from "../../../functions/api/delivery"
import { apiGetProduct } from "../../../functions/api/product"
import { CartItem, Customer, Product } from "../../../types"
import { ACTIVE_EVENT_ID, DELIVERY_LOCAL_KEY, PAGE } from "../../../values"
import { Delivery } from "./../../../types/index"
import { navigate } from 'gatsby';

const SINGLE_DELIVERY_STORAGE = "SINGLE_DELIVERY_STORAGE"

const useDeliveryReducer = () => {
  const [delivery, setDelivery] = useState<Delivery>(({
    orderDate: new Date().toISOString(),
    customer: {
      name: "",
      address1: "",
      address2: "",
      phone: "",
    },
    items: [],
    status: DeliveryStatus.ORDERED,
    note: "",
    pointNumber: "",
    paymentType: PaymentType.CASH,
  } as unknown) as Delivery)
  const [isSending, setIsSending] = useState<boolean>(false)
  const [hasSentDelivery, setHasSentDelivery] = useState<boolean>(false)

  useEffect(() => {
    const loadSingleDelivery = async() => {
      const jsonValue = window.localStorage.getItem(SINGLE_DELIVERY_STORAGE)
      if (jsonValue) {
        const localSingleDelivery = JSON.parse(jsonValue) as Delivery

        let tmp = []

        for (let i = 0; i < localSingleDelivery.items.length; i++) {
          let product = await apiGetProduct(localSingleDelivery.items[i].product.id)
          console.dir(product)
          if (product!=null) {
            console.log('called')
            localSingleDelivery.items[i].product = product
            tmp.push(localSingleDelivery.items[i])
          }
        }

        localSingleDelivery.items = tmp
        setDelivery(localSingleDelivery)
      }
    }

    loadSingleDelivery()
  }, [])

  function getCartItemTotalPrice() {
    let sum = 0
    delivery.items.forEach(item => {
      if (
        item.product.eventId == ACTIVE_EVENT_ID &&
        item.product.event &&
        item.product.event.isActive &&
        new Date(item.product.event.startDate) <= new Date() &&
        new Date(item.product.event.endDate) >= new Date() &&
        item.product.eventPrice
      ) {
        sum += item.count * item.product.eventPrice
      } else {
        sum += item.count * item.product.price
      }
    })

    return sum
  }

  function addCartItem(product: Product, count: number) {
    let max = 0

    for (let i = 0; i < delivery.items.length; i++) {
      if (delivery.items[i].product.id === product.id) {
        delivery.items[i].count += count
        setDelivery({ ...delivery })
        window.localStorage.setItem(SINGLE_DELIVERY_STORAGE, JSON.stringify(delivery))
        return
      }

      const itemIdInNumber = parseInt(delivery.items[i].id)
      if (max < itemIdInNumber) {
        max = itemIdInNumber
      }
    }

    delivery.items.push({
      id: max + 1 + "",
      product: product,
      count: count,
    })

    window.localStorage.setItem(SINGLE_DELIVERY_STORAGE, JSON.stringify(delivery))
    setDelivery({ ...delivery })
  }

  function setCartItemCount(cart: CartItem, newCount: number) {
    cart.count = newCount
    const targetIndex = delivery.items.findIndex(item => item.id === cart.id)
    delivery.items[targetIndex] = cart

    window.localStorage.setItem(SINGLE_DELIVERY_STORAGE, JSON.stringify(delivery))
    setDelivery({ ...delivery })
  }

  function deleteCartItem(cart: CartItem) {
    delivery.items = delivery.items.filter(item => item.id !== cart.id)
    window.localStorage.setItem(SINGLE_DELIVERY_STORAGE, JSON.stringify(delivery))
    setDelivery({ ...delivery })
  }

  function setPaymentMethod(value: PaymentType) {
    delivery.paymentType = value
    window.localStorage.setItem(SINGLE_DELIVERY_STORAGE, JSON.stringify(delivery))
    setDelivery({ ...delivery })
  }

  function setCustomerName(value: string) {
    delivery.customer.name = value
    window.localStorage.setItem(SINGLE_DELIVERY_STORAGE, JSON.stringify(delivery))
    setDelivery({ ...delivery })
  }

  function setCustomerAddress1(value: string) {
    delivery.customer.address1 = value
    window.localStorage.setItem(SINGLE_DELIVERY_STORAGE, JSON.stringify(delivery))
    setDelivery({ ...delivery })
  }

  function setCustomerAddress2(value: string) {
    delivery.customer.address2 = value
    window.localStorage.setItem(SINGLE_DELIVERY_STORAGE, JSON.stringify(delivery))
    setDelivery({ ...delivery })
  }

  function setCustomerPhone(value: string) {
    delivery.customer.phone = value
    window.localStorage.setItem(SINGLE_DELIVERY_STORAGE, JSON.stringify(delivery))
    setDelivery({ ...delivery })
  }

  function setCustomer(customer: Customer) {
    delivery.customer = customer
    window.localStorage.setItem(SINGLE_DELIVERY_STORAGE, JSON.stringify(delivery))
    setDelivery({ ...delivery })
  }

  function setPointNumber(value: string) {
    delivery.pointNumber = value
    window.localStorage.setItem(SINGLE_DELIVERY_STORAGE, JSON.stringify(delivery))
    setDelivery({ ...delivery })
  }

  function setDeliveryNote(value: string) {
    delivery.note = value
    setDelivery({ ...delivery })
  }

  function validateDelivery(): string {
    let sum = getCartItemTotalPrice()

    if (sum < 1) return "물건을 최소 하나 담아주세요 : " + numberWithCommas(sum)

    const phoneNumberReg = /^\d{10,11}$/
    const pointNumberReg = /^\d{4}$/

    if (delivery.customer.name === "") {
      return "이름을 입력해주세요"
    } else if (delivery.customer.name.length >= 20) {
      return "이름이 너무 깁니다"
    } else if (delivery.customer.address1 === "" && delivery.customer.address2 === "") {
      return "주소를 입력해주세요"
    } else if (delivery.customer.address1.length >= 60 || delivery.customer.address2.length >= 60) {
      return "주소가 너무 깁니다"
    } else if (delivery.customer.phone === "") {
      return "휴대폰 번호를 입력해주세요"
    } else if (!phoneNumberReg.test(delivery.customer.phone)) {
      return "올바른 휴대폰 번호를 입력해주세요"
    } else if (!(pointNumberReg.test(delivery.pointNumber) || delivery.pointNumber.length === 0)) {
      return "올바른 포인트 번호를 입력해주세요(전화번호 뒷 4자리)"
    } else if (delivery.note.length > 100) {
      return "메모가 너무 깁니다"
    } else {
      return "OK"
    }
  }

  function submitDelivery() {
    if (validateDelivery() === "OK") {
      ;(async () => {
        const { items, note, pointNumber, paymentType } = delivery
        const { id, name, address1, address2, phone } = delivery.customer

        try {
          setIsSending(true)

          const newDelivery = await apiCreateDelivery({
            customer: delivery.customer,
            items,
            orderDate: new Date().toLocaleDateString(),
            orderDateTime: new Date().toISOString(),
            status: DeliveryStatus.ORDERED,
            note,
            pointNumber,
            paymentType,
          })

          if (newDelivery) {
            delivery.items = []
            window.localStorage.setItem(SINGLE_DELIVERY_STORAGE, JSON.stringify(delivery))

            const jsonValue = window.localStorage.getItem(DELIVERY_LOCAL_KEY)
            if (jsonValue) {
              let localDeliveryIds = JSON.parse(jsonValue) as string[]
              window.localStorage.setItem(
                DELIVERY_LOCAL_KEY,
                JSON.stringify([newDelivery.id].concat(localDeliveryIds))
              )
            } else {
              window.localStorage.setItem(
                DELIVERY_LOCAL_KEY,
                JSON.stringify([newDelivery.id])
              )
            }

            setDelivery({ ...delivery })

            alert("주문 접수 완료!")
            navigate(PAGE.DELIVERY)
          }
        } catch (error) {
          handleError(error)
        }

        setIsSending(false)
        setHasSentDelivery(true)
      })()
      return true
    } else {
      return false
    }
  }

  return {
    deliveryStates: { delivery: delivery },
    getCartItemTotalPrice,
    validateDelivery,
    addCartItem,
    setCartItemCount,
    deleteCartItem,
    setCustomer,
    setCustomerName,
    setCustomerAddress1,
    setCustomerAddress2,
    setCustomerPhone,
    setPointNumber,
    setDeliveryNote,
    setPaymentMethod,
    submitDelivery,
    isSending,
    hasSentDelivery,
    setHasSentDelivery
  }
}

export default useDeliveryReducer
