/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAnnouncementInput = {
  id?: string | null,
  title: string,
  content: string,
  date: string,
};

export type ModelAnnouncementConditionInput = {
  title?: ModelStringInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelAnnouncementConditionInput | null > | null,
  or?: Array< ModelAnnouncementConditionInput | null > | null,
  not?: ModelAnnouncementConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Announcement = {
  __typename: "Announcement",
  id?: string,
  title?: string,
  content?: string,
  date?: string,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateAnnouncementInput = {
  id: string,
  title?: string | null,
  content?: string | null,
  date: string,
};

export type DeleteAnnouncementInput = {
  id: string,
  date: string,
};

export type CreateEventInput = {
  id?: string | null,
  name: string,
  startDate?: string | null,
  endDate?: string | null,
  isActive?: boolean | null,
};

export type ModelEventConditionInput = {
  name?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Event = {
  __typename: "Event",
  id?: string,
  name?: string,
  startDate?: string | null,
  endDate?: string | null,
  isActive?: boolean | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateEventInput = {
  id: string,
  name?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  isActive?: boolean | null,
};

export type DeleteEventInput = {
  id?: string | null,
};

export type CreateCategoryInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  color?: string | null,
  icon: IconType,
};

export enum IconType {
  MEAT = "MEAT",
  FISH = "FISH",
  BULK = "BULK",
  FRUIT = "FRUIT",
  VEGGIE = "VEGGIE",
  GRAIN = "GRAIN",
  DAIRY = "DAIRY",
  DRINK = "DRINK",
  CONSUMABLE = "CONSUMABLE",
  INSTANT = "INSTANT",
  FROZEN = "FROZEN",
  SAUCE = "SAUCE",
  DRIED = "DRIED",
  OIL = "OIL",
  UNDERWEAR = "UNDERWEAR",
  CLEANING = "CLEANING",
  COFFEE = "COFFEE",
  RAMEN = "RAMEN",
  BREAD = "BREAD",
  PASTRY = "PASTRY",
  DAILY = "DAILY",
}


export type ModelCategoryConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  color?: ModelStringInput | null,
  icon?: ModelIconTypeInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
};

export type ModelIconTypeInput = {
  eq?: IconType | null,
  ne?: IconType | null,
};

export type Category = {
  __typename: "Category",
  id?: string,
  name?: string,
  description?: string | null,
  color?: string | null,
  icon?: IconType,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateCategoryInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  color?: string | null,
  icon?: IconType | null,
};

export type DeleteCategoryInput = {
  id?: string | null,
};

export type CreateStoreHourInput = {
  id?: string | null,
  startTime: string,
  endTime: string,
  text1?: string | null,
  text2?: string | null,
  text3?: string | null,
  text4?: string | null,
  isForceDisabled: boolean,
};

export type ModelStoreHourConditionInput = {
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  text1?: ModelStringInput | null,
  text2?: ModelStringInput | null,
  text3?: ModelStringInput | null,
  text4?: ModelStringInput | null,
  isForceDisabled?: ModelBooleanInput | null,
  and?: Array< ModelStoreHourConditionInput | null > | null,
  or?: Array< ModelStoreHourConditionInput | null > | null,
  not?: ModelStoreHourConditionInput | null,
};

export type StoreHour = {
  __typename: "StoreHour",
  id?: string,
  startTime?: string,
  endTime?: string,
  text1?: string | null,
  text2?: string | null,
  text3?: string | null,
  text4?: string | null,
  isForceDisabled?: boolean,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateStoreHourInput = {
  id: string,
  startTime?: string | null,
  endTime?: string | null,
  text1?: string | null,
  text2?: string | null,
  text3?: string | null,
  text4?: string | null,
  isForceDisabled?: boolean | null,
};

export type DeleteStoreHourInput = {
  id?: string | null,
};

export type CreateProductInput = {
  id?: string | null,
  categoryId: string,
  name: string,
  price: number,
  uri?: string | null,
  barcodePictureUri?: string | null,
  eventId: string,
  eventPrice?: number | null,
  eventPercentage?: number | null,
  status?: ProductStatus | null,
};

export enum ProductStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type ModelProductConditionInput = {
  categoryId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  price?: ModelIntInput | null,
  uri?: ModelStringInput | null,
  barcodePictureUri?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  eventPrice?: ModelIntInput | null,
  eventPercentage?: ModelIntInput | null,
  status?: ModelProductStatusInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelProductStatusInput = {
  eq?: ProductStatus | null,
  ne?: ProductStatus | null,
};

export type Product = {
  __typename: "Product",
  id?: string,
  categoryId?: string,
  category?: Category,
  name?: string,
  price?: number,
  uri?: string | null,
  barcodePictureUri?: string | null,
  eventId?: string,
  event?: Event,
  eventPrice?: number | null,
  eventPercentage?: number | null,
  status?: ProductStatus | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateProductInput = {
  id: string,
  categoryId?: string | null,
  name?: string | null,
  price?: number | null,
  uri?: string | null,
  barcodePictureUri?: string | null,
  eventId?: string | null,
  eventPrice?: number | null,
  eventPercentage?: number | null,
  status?: ProductStatus | null,
};

export type DeleteProductInput = {
  id?: string | null,
};

export type CreateCartInput = {
  id?: string | null,
  productId: string,
  count: number,
  deliveryId: string,
};

export type ModelCartConditionInput = {
  productId?: ModelIDInput | null,
  count?: ModelIntInput | null,
  deliveryId?: ModelIDInput | null,
  and?: Array< ModelCartConditionInput | null > | null,
  or?: Array< ModelCartConditionInput | null > | null,
  not?: ModelCartConditionInput | null,
};

export type Cart = {
  __typename: "Cart",
  id?: string,
  productId?: string,
  product?: Product,
  count?: number,
  deliveryId?: string,
  delivery?: Delivery,
  createdAt?: string,
  updatedAt?: string,
};

export type Delivery = {
  __typename: "Delivery",
  id?: string,
  status?: DeliveryStatus,
  orderDateTime?: string,
  items?: Array< string | null >,
  customer?: string,
  orderDate?: string,
  note?: string,
  pointNumber?: string,
  paymentType?: PaymentType,
  cancelledReason?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export enum DeliveryStatus {
  ORDERED = "ORDERED",
  OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY",
  DELIVERED = "DELIVERED",
  CANCELLED = "CANCELLED",
}


export enum PaymentType {
  CASH = "CASH",
  CREDIT = "CREDIT",
}


export type UpdateCartInput = {
  id: string,
  productId?: string | null,
  count?: number | null,
  deliveryId?: string | null,
};

export type DeleteCartInput = {
  id?: string | null,
};

export type CreateDeliveryInput = {
  id?: string | null,
  status: DeliveryStatus,
  orderDateTime: string,
  items: Array< string | null >,
  customer: string,
  orderDate: string,
  note: string,
  pointNumber: string,
  paymentType: PaymentType,
  cancelledReason?: string | null,
};

export type ModelDeliveryConditionInput = {
  status?: ModelDeliveryStatusInput | null,
  orderDateTime?: ModelStringInput | null,
  items?: ModelStringInput | null,
  customer?: ModelStringInput | null,
  orderDate?: ModelStringInput | null,
  note?: ModelStringInput | null,
  pointNumber?: ModelStringInput | null,
  paymentType?: ModelPaymentTypeInput | null,
  cancelledReason?: ModelStringInput | null,
  and?: Array< ModelDeliveryConditionInput | null > | null,
  or?: Array< ModelDeliveryConditionInput | null > | null,
  not?: ModelDeliveryConditionInput | null,
};

export type ModelDeliveryStatusInput = {
  eq?: DeliveryStatus | null,
  ne?: DeliveryStatus | null,
};

export type ModelPaymentTypeInput = {
  eq?: PaymentType | null,
  ne?: PaymentType | null,
};

export type UpdateDeliveryInput = {
  id: string,
  status?: DeliveryStatus | null,
  orderDateTime?: string | null,
  items?: Array< string | null > | null,
  customer?: string | null,
  orderDate?: string | null,
  note?: string | null,
  pointNumber?: string | null,
  paymentType?: PaymentType | null,
  cancelledReason?: string | null,
};

export type DeleteDeliveryInput = {
  id?: string | null,
};

export type CreateBannerInput = {
  id?: string | null,
  uri: string,
  mobileUri: string,
};

export type ModelBannerConditionInput = {
  uri?: ModelStringInput | null,
  mobileUri?: ModelStringInput | null,
  and?: Array< ModelBannerConditionInput | null > | null,
  or?: Array< ModelBannerConditionInput | null > | null,
  not?: ModelBannerConditionInput | null,
};

export type Banner = {
  __typename: "Banner",
  id?: string,
  uri?: string,
  mobileUri?: string,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateBannerInput = {
  id: string,
  uri?: string | null,
  mobileUri?: string | null,
};

export type DeleteBannerInput = {
  id?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelAnnouncementFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  content?: ModelStringInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelAnnouncementFilterInput | null > | null,
  or?: Array< ModelAnnouncementFilterInput | null > | null,
  not?: ModelAnnouncementFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAnnouncementConnection = {
  __typename: "ModelAnnouncementConnection",
  items?:  Array<Announcement | null > | null,
  nextToken?: string | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items?:  Array<Event | null > | null,
  nextToken?: string | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  color?: ModelStringInput | null,
  icon?: ModelIconTypeInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items?:  Array<Category | null > | null,
  nextToken?: string | null,
};

export type ModelStoreHourFilterInput = {
  id?: ModelIDInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  text1?: ModelStringInput | null,
  text2?: ModelStringInput | null,
  text3?: ModelStringInput | null,
  text4?: ModelStringInput | null,
  isForceDisabled?: ModelBooleanInput | null,
  and?: Array< ModelStoreHourFilterInput | null > | null,
  or?: Array< ModelStoreHourFilterInput | null > | null,
  not?: ModelStoreHourFilterInput | null,
};

export type ModelStoreHourConnection = {
  __typename: "ModelStoreHourConnection",
  items?:  Array<StoreHour | null > | null,
  nextToken?: string | null,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  categoryId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  price?: ModelIntInput | null,
  uri?: ModelStringInput | null,
  barcodePictureUri?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  eventPrice?: ModelIntInput | null,
  eventPercentage?: ModelIntInput | null,
  status?: ModelProductStatusInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items?:  Array<Product | null > | null,
  nextToken?: string | null,
};

export type ModelCartFilterInput = {
  id?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  count?: ModelIntInput | null,
  deliveryId?: ModelIDInput | null,
  and?: Array< ModelCartFilterInput | null > | null,
  or?: Array< ModelCartFilterInput | null > | null,
  not?: ModelCartFilterInput | null,
};

export type ModelCartConnection = {
  __typename: "ModelCartConnection",
  items?:  Array<Cart | null > | null,
  nextToken?: string | null,
};

export type ModelDeliveryFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelDeliveryStatusInput | null,
  orderDateTime?: ModelStringInput | null,
  items?: ModelStringInput | null,
  customer?: ModelStringInput | null,
  orderDate?: ModelStringInput | null,
  note?: ModelStringInput | null,
  pointNumber?: ModelStringInput | null,
  paymentType?: ModelPaymentTypeInput | null,
  cancelledReason?: ModelStringInput | null,
  and?: Array< ModelDeliveryFilterInput | null > | null,
  or?: Array< ModelDeliveryFilterInput | null > | null,
  not?: ModelDeliveryFilterInput | null,
};

export type ModelDeliveryConnection = {
  __typename: "ModelDeliveryConnection",
  items?:  Array<Delivery | null > | null,
  nextToken?: string | null,
};

export type ModelBannerFilterInput = {
  id?: ModelIDInput | null,
  uri?: ModelStringInput | null,
  mobileUri?: ModelStringInput | null,
  and?: Array< ModelBannerFilterInput | null > | null,
  or?: Array< ModelBannerFilterInput | null > | null,
  not?: ModelBannerFilterInput | null,
};

export type ModelBannerConnection = {
  __typename: "ModelBannerConnection",
  items?:  Array<Banner | null > | null,
  nextToken?: string | null,
};

export type CreateAnnouncementMutationVariables = {
  input?: CreateAnnouncementInput,
  condition?: ModelAnnouncementConditionInput | null,
};

export type CreateAnnouncementMutation = {
  createAnnouncement?:  {
    __typename: "Announcement",
    id: string,
    title: string,
    content: string,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAnnouncementMutationVariables = {
  input?: UpdateAnnouncementInput,
  condition?: ModelAnnouncementConditionInput | null,
};

export type UpdateAnnouncementMutation = {
  updateAnnouncement?:  {
    __typename: "Announcement",
    id: string,
    title: string,
    content: string,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAnnouncementMutationVariables = {
  input?: DeleteAnnouncementInput,
  condition?: ModelAnnouncementConditionInput | null,
};

export type DeleteAnnouncementMutation = {
  deleteAnnouncement?:  {
    __typename: "Announcement",
    id: string,
    title: string,
    content: string,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEventMutationVariables = {
  input?: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    startDate?: string | null,
    endDate?: string | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEventMutationVariables = {
  input?: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    startDate?: string | null,
    endDate?: string | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEventMutationVariables = {
  input?: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    startDate?: string | null,
    endDate?: string | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input?: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    description?: string | null,
    color?: string | null,
    icon: IconType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input?: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    description?: string | null,
    color?: string | null,
    icon: IconType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input?: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    description?: string | null,
    color?: string | null,
    icon: IconType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateStoreHourMutationVariables = {
  input?: CreateStoreHourInput,
  condition?: ModelStoreHourConditionInput | null,
};

export type CreateStoreHourMutation = {
  createStoreHour?:  {
    __typename: "StoreHour",
    id: string,
    startTime: string,
    endTime: string,
    text1?: string | null,
    text2?: string | null,
    text3?: string | null,
    text4?: string | null,
    isForceDisabled: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStoreHourMutationVariables = {
  input?: UpdateStoreHourInput,
  condition?: ModelStoreHourConditionInput | null,
};

export type UpdateStoreHourMutation = {
  updateStoreHour?:  {
    __typename: "StoreHour",
    id: string,
    startTime: string,
    endTime: string,
    text1?: string | null,
    text2?: string | null,
    text3?: string | null,
    text4?: string | null,
    isForceDisabled: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStoreHourMutationVariables = {
  input?: DeleteStoreHourInput,
  condition?: ModelStoreHourConditionInput | null,
};

export type DeleteStoreHourMutation = {
  deleteStoreHour?:  {
    __typename: "StoreHour",
    id: string,
    startTime: string,
    endTime: string,
    text1?: string | null,
    text2?: string | null,
    text3?: string | null,
    text4?: string | null,
    isForceDisabled: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProductMutationVariables = {
  input?: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    categoryId: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      color?: string | null,
      icon: IconType,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    price: number,
    uri?: string | null,
    barcodePictureUri?: string | null,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      startDate?: string | null,
      endDate?: string | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    eventPrice?: number | null,
    eventPercentage?: number | null,
    status?: ProductStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductMutationVariables = {
  input?: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    categoryId: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      color?: string | null,
      icon: IconType,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    price: number,
    uri?: string | null,
    barcodePictureUri?: string | null,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      startDate?: string | null,
      endDate?: string | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    eventPrice?: number | null,
    eventPercentage?: number | null,
    status?: ProductStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductMutationVariables = {
  input?: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    categoryId: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      color?: string | null,
      icon: IconType,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    price: number,
    uri?: string | null,
    barcodePictureUri?: string | null,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      startDate?: string | null,
      endDate?: string | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    eventPrice?: number | null,
    eventPercentage?: number | null,
    status?: ProductStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCartMutationVariables = {
  input?: CreateCartInput,
  condition?: ModelCartConditionInput | null,
};

export type CreateCartMutation = {
  createCart?:  {
    __typename: "Cart",
    id: string,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      categoryId: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        description?: string | null,
        color?: string | null,
        icon: IconType,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      price: number,
      uri?: string | null,
      barcodePictureUri?: string | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        startDate?: string | null,
        endDate?: string | null,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      eventPrice?: number | null,
      eventPercentage?: number | null,
      status?: ProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    count: number,
    deliveryId: string,
    delivery?:  {
      __typename: "Delivery",
      id: string,
      status: DeliveryStatus,
      orderDateTime: string,
      items: Array< string | null >,
      customer: string,
      orderDate: string,
      note: string,
      pointNumber: string,
      paymentType: PaymentType,
      cancelledReason?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCartMutationVariables = {
  input?: UpdateCartInput,
  condition?: ModelCartConditionInput | null,
};

export type UpdateCartMutation = {
  updateCart?:  {
    __typename: "Cart",
    id: string,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      categoryId: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        description?: string | null,
        color?: string | null,
        icon: IconType,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      price: number,
      uri?: string | null,
      barcodePictureUri?: string | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        startDate?: string | null,
        endDate?: string | null,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      eventPrice?: number | null,
      eventPercentage?: number | null,
      status?: ProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    count: number,
    deliveryId: string,
    delivery?:  {
      __typename: "Delivery",
      id: string,
      status: DeliveryStatus,
      orderDateTime: string,
      items: Array< string | null >,
      customer: string,
      orderDate: string,
      note: string,
      pointNumber: string,
      paymentType: PaymentType,
      cancelledReason?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCartMutationVariables = {
  input?: DeleteCartInput,
  condition?: ModelCartConditionInput | null,
};

export type DeleteCartMutation = {
  deleteCart?:  {
    __typename: "Cart",
    id: string,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      categoryId: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        description?: string | null,
        color?: string | null,
        icon: IconType,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      price: number,
      uri?: string | null,
      barcodePictureUri?: string | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        startDate?: string | null,
        endDate?: string | null,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      eventPrice?: number | null,
      eventPercentage?: number | null,
      status?: ProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    count: number,
    deliveryId: string,
    delivery?:  {
      __typename: "Delivery",
      id: string,
      status: DeliveryStatus,
      orderDateTime: string,
      items: Array< string | null >,
      customer: string,
      orderDate: string,
      note: string,
      pointNumber: string,
      paymentType: PaymentType,
      cancelledReason?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDeliveryMutationVariables = {
  input?: CreateDeliveryInput,
  condition?: ModelDeliveryConditionInput | null,
};

export type CreateDeliveryMutation = {
  createDelivery?:  {
    __typename: "Delivery",
    id: string,
    status: DeliveryStatus,
    orderDateTime: string,
    items: Array< string | null >,
    customer: string,
    orderDate: string,
    note: string,
    pointNumber: string,
    paymentType: PaymentType,
    cancelledReason?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDeliveryMutationVariables = {
  input?: UpdateDeliveryInput,
  condition?: ModelDeliveryConditionInput | null,
};

export type UpdateDeliveryMutation = {
  updateDelivery?:  {
    __typename: "Delivery",
    id: string,
    status: DeliveryStatus,
    orderDateTime: string,
    items: Array< string | null >,
    customer: string,
    orderDate: string,
    note: string,
    pointNumber: string,
    paymentType: PaymentType,
    cancelledReason?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDeliveryMutationVariables = {
  input?: DeleteDeliveryInput,
  condition?: ModelDeliveryConditionInput | null,
};

export type DeleteDeliveryMutation = {
  deleteDelivery?:  {
    __typename: "Delivery",
    id: string,
    status: DeliveryStatus,
    orderDateTime: string,
    items: Array< string | null >,
    customer: string,
    orderDate: string,
    note: string,
    pointNumber: string,
    paymentType: PaymentType,
    cancelledReason?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBannerMutationVariables = {
  input?: CreateBannerInput,
  condition?: ModelBannerConditionInput | null,
};

export type CreateBannerMutation = {
  createBanner?:  {
    __typename: "Banner",
    id: string,
    uri: string,
    mobileUri: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBannerMutationVariables = {
  input?: UpdateBannerInput,
  condition?: ModelBannerConditionInput | null,
};

export type UpdateBannerMutation = {
  updateBanner?:  {
    __typename: "Banner",
    id: string,
    uri: string,
    mobileUri: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBannerMutationVariables = {
  input?: DeleteBannerInput,
  condition?: ModelBannerConditionInput | null,
};

export type DeleteBannerMutation = {
  deleteBanner?:  {
    __typename: "Banner",
    id: string,
    uri: string,
    mobileUri: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetAnnouncementQueryVariables = {
  id?: string,
  date?: string,
};

export type GetAnnouncementQuery = {
  getAnnouncement?:  {
    __typename: "Announcement",
    id: string,
    title: string,
    content: string,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAnnouncementsQueryVariables = {
  id?: string | null,
  date?: ModelStringKeyConditionInput | null,
  filter?: ModelAnnouncementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAnnouncementsQuery = {
  listAnnouncements?:  {
    __typename: "ModelAnnouncementConnection",
    items?:  Array< {
      __typename: "Announcement",
      id: string,
      title: string,
      content: string,
      date: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetEventQueryVariables = {
  id?: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    startDate?: string | null,
    endDate?: string | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      name: string,
      startDate?: string | null,
      endDate?: string | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id?: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    description?: string | null,
    color?: string | null,
    icon: IconType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCategorysQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategorysQuery = {
  listCategorys?:  {
    __typename: "ModelCategoryConnection",
    items?:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      color?: string | null,
      icon: IconType,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetStoreHourQueryVariables = {
  id?: string,
};

export type GetStoreHourQuery = {
  getStoreHour?:  {
    __typename: "StoreHour",
    id: string,
    startTime: string,
    endTime: string,
    text1?: string | null,
    text2?: string | null,
    text3?: string | null,
    text4?: string | null,
    isForceDisabled: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStoreHoursQueryVariables = {
  filter?: ModelStoreHourFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStoreHoursQuery = {
  listStoreHours?:  {
    __typename: "ModelStoreHourConnection",
    items?:  Array< {
      __typename: "StoreHour",
      id: string,
      startTime: string,
      endTime: string,
      text1?: string | null,
      text2?: string | null,
      text3?: string | null,
      text4?: string | null,
      isForceDisabled: boolean,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetProductQueryVariables = {
  id?: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    categoryId: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      color?: string | null,
      icon: IconType,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    price: number,
    uri?: string | null,
    barcodePictureUri?: string | null,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      startDate?: string | null,
      endDate?: string | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    eventPrice?: number | null,
    eventPercentage?: number | null,
    status?: ProductStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items?:  Array< {
      __typename: "Product",
      id: string,
      categoryId: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        description?: string | null,
        color?: string | null,
        icon: IconType,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      price: number,
      uri?: string | null,
      barcodePictureUri?: string | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        startDate?: string | null,
        endDate?: string | null,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      eventPrice?: number | null,
      eventPercentage?: number | null,
      status?: ProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCartQueryVariables = {
  id?: string,
};

export type GetCartQuery = {
  getCart?:  {
    __typename: "Cart",
    id: string,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      categoryId: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        description?: string | null,
        color?: string | null,
        icon: IconType,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      price: number,
      uri?: string | null,
      barcodePictureUri?: string | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        startDate?: string | null,
        endDate?: string | null,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      eventPrice?: number | null,
      eventPercentage?: number | null,
      status?: ProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    count: number,
    deliveryId: string,
    delivery?:  {
      __typename: "Delivery",
      id: string,
      status: DeliveryStatus,
      orderDateTime: string,
      items: Array< string | null >,
      customer: string,
      orderDate: string,
      note: string,
      pointNumber: string,
      paymentType: PaymentType,
      cancelledReason?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCartsQueryVariables = {
  filter?: ModelCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCartsQuery = {
  listCarts?:  {
    __typename: "ModelCartConnection",
    items?:  Array< {
      __typename: "Cart",
      id: string,
      productId: string,
      product?:  {
        __typename: "Product",
        id: string,
        categoryId: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          description?: string | null,
          color?: string | null,
          icon: IconType,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        price: number,
        uri?: string | null,
        barcodePictureUri?: string | null,
        eventId: string,
        event?:  {
          __typename: "Event",
          id: string,
          name: string,
          startDate?: string | null,
          endDate?: string | null,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        eventPrice?: number | null,
        eventPercentage?: number | null,
        status?: ProductStatus | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      count: number,
      deliveryId: string,
      delivery?:  {
        __typename: "Delivery",
        id: string,
        status: DeliveryStatus,
        orderDateTime: string,
        items: Array< string | null >,
        customer: string,
        orderDate: string,
        note: string,
        pointNumber: string,
        paymentType: PaymentType,
        cancelledReason?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetDeliveryQueryVariables = {
  id?: string,
};

export type GetDeliveryQuery = {
  getDelivery?:  {
    __typename: "Delivery",
    id: string,
    status: DeliveryStatus,
    orderDateTime: string,
    items: Array< string | null >,
    customer: string,
    orderDate: string,
    note: string,
    pointNumber: string,
    paymentType: PaymentType,
    cancelledReason?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDeliverysQueryVariables = {
  filter?: ModelDeliveryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeliverysQuery = {
  listDeliverys?:  {
    __typename: "ModelDeliveryConnection",
    items?:  Array< {
      __typename: "Delivery",
      id: string,
      status: DeliveryStatus,
      orderDateTime: string,
      items: Array< string | null >,
      customer: string,
      orderDate: string,
      note: string,
      pointNumber: string,
      paymentType: PaymentType,
      cancelledReason?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetBannerQueryVariables = {
  id?: string,
};

export type GetBannerQuery = {
  getBanner?:  {
    __typename: "Banner",
    id: string,
    uri: string,
    mobileUri: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBannersQueryVariables = {
  filter?: ModelBannerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBannersQuery = {
  listBanners?:  {
    __typename: "ModelBannerConnection",
    items?:  Array< {
      __typename: "Banner",
      id: string,
      uri: string,
      mobileUri: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ProductsByEventQueryVariables = {
  eventId?: string | null,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductsByEventQuery = {
  productsByEvent?:  {
    __typename: "ModelProductConnection",
    items?:  Array< {
      __typename: "Product",
      id: string,
      categoryId: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        description?: string | null,
        color?: string | null,
        icon: IconType,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      price: number,
      uri?: string | null,
      barcodePictureUri?: string | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        startDate?: string | null,
        endDate?: string | null,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      eventPrice?: number | null,
      eventPercentage?: number | null,
      status?: ProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ProductsByCategoryQueryVariables = {
  categoryId?: string | null,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductsByCategoryQuery = {
  productsByCategory?:  {
    __typename: "ModelProductConnection",
    items?:  Array< {
      __typename: "Product",
      id: string,
      categoryId: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        description?: string | null,
        color?: string | null,
        icon: IconType,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      price: number,
      uri?: string | null,
      barcodePictureUri?: string | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        startDate?: string | null,
        endDate?: string | null,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      eventPrice?: number | null,
      eventPercentage?: number | null,
      status?: ProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type DeliverysByStatusQueryVariables = {
  status?: DeliveryStatus | null,
  orderDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeliveryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DeliverysByStatusQuery = {
  deliverysByStatus?:  {
    __typename: "ModelDeliveryConnection",
    items?:  Array< {
      __typename: "Delivery",
      id: string,
      status: DeliveryStatus,
      orderDateTime: string,
      items: Array< string | null >,
      customer: string,
      orderDate: string,
      note: string,
      pointNumber: string,
      paymentType: PaymentType,
      cancelledReason?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type DeliverysByOrderDateQueryVariables = {
  orderDate?: string | null,
  orderDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeliveryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DeliverysByOrderDateQuery = {
  deliverysByOrderDate?:  {
    __typename: "ModelDeliveryConnection",
    items?:  Array< {
      __typename: "Delivery",
      id: string,
      status: DeliveryStatus,
      orderDateTime: string,
      items: Array< string | null >,
      customer: string,
      orderDate: string,
      note: string,
      pointNumber: string,
      paymentType: PaymentType,
      cancelledReason?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type OnCreateAnnouncementSubscription = {
  onCreateAnnouncement?:  {
    __typename: "Announcement",
    id: string,
    title: string,
    content: string,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAnnouncementSubscription = {
  onUpdateAnnouncement?:  {
    __typename: "Announcement",
    id: string,
    title: string,
    content: string,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAnnouncementSubscription = {
  onDeleteAnnouncement?:  {
    __typename: "Announcement",
    id: string,
    title: string,
    content: string,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    startDate?: string | null,
    endDate?: string | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    startDate?: string | null,
    endDate?: string | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    startDate?: string | null,
    endDate?: string | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    description?: string | null,
    color?: string | null,
    icon: IconType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    description?: string | null,
    color?: string | null,
    icon: IconType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    description?: string | null,
    color?: string | null,
    icon: IconType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateStoreHourSubscription = {
  onCreateStoreHour?:  {
    __typename: "StoreHour",
    id: string,
    startTime: string,
    endTime: string,
    text1?: string | null,
    text2?: string | null,
    text3?: string | null,
    text4?: string | null,
    isForceDisabled: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateStoreHourSubscription = {
  onUpdateStoreHour?:  {
    __typename: "StoreHour",
    id: string,
    startTime: string,
    endTime: string,
    text1?: string | null,
    text2?: string | null,
    text3?: string | null,
    text4?: string | null,
    isForceDisabled: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteStoreHourSubscription = {
  onDeleteStoreHour?:  {
    __typename: "StoreHour",
    id: string,
    startTime: string,
    endTime: string,
    text1?: string | null,
    text2?: string | null,
    text3?: string | null,
    text4?: string | null,
    isForceDisabled: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    categoryId: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      color?: string | null,
      icon: IconType,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    price: number,
    uri?: string | null,
    barcodePictureUri?: string | null,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      startDate?: string | null,
      endDate?: string | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    eventPrice?: number | null,
    eventPercentage?: number | null,
    status?: ProductStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    categoryId: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      color?: string | null,
      icon: IconType,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    price: number,
    uri?: string | null,
    barcodePictureUri?: string | null,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      startDate?: string | null,
      endDate?: string | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    eventPrice?: number | null,
    eventPercentage?: number | null,
    status?: ProductStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    categoryId: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      color?: string | null,
      icon: IconType,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    price: number,
    uri?: string | null,
    barcodePictureUri?: string | null,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      startDate?: string | null,
      endDate?: string | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    eventPrice?: number | null,
    eventPercentage?: number | null,
    status?: ProductStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCartSubscription = {
  onCreateCart?:  {
    __typename: "Cart",
    id: string,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      categoryId: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        description?: string | null,
        color?: string | null,
        icon: IconType,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      price: number,
      uri?: string | null,
      barcodePictureUri?: string | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        startDate?: string | null,
        endDate?: string | null,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      eventPrice?: number | null,
      eventPercentage?: number | null,
      status?: ProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    count: number,
    deliveryId: string,
    delivery?:  {
      __typename: "Delivery",
      id: string,
      status: DeliveryStatus,
      orderDateTime: string,
      items: Array< string | null >,
      customer: string,
      orderDate: string,
      note: string,
      pointNumber: string,
      paymentType: PaymentType,
      cancelledReason?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCartSubscription = {
  onUpdateCart?:  {
    __typename: "Cart",
    id: string,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      categoryId: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        description?: string | null,
        color?: string | null,
        icon: IconType,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      price: number,
      uri?: string | null,
      barcodePictureUri?: string | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        startDate?: string | null,
        endDate?: string | null,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      eventPrice?: number | null,
      eventPercentage?: number | null,
      status?: ProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    count: number,
    deliveryId: string,
    delivery?:  {
      __typename: "Delivery",
      id: string,
      status: DeliveryStatus,
      orderDateTime: string,
      items: Array< string | null >,
      customer: string,
      orderDate: string,
      note: string,
      pointNumber: string,
      paymentType: PaymentType,
      cancelledReason?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCartSubscription = {
  onDeleteCart?:  {
    __typename: "Cart",
    id: string,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      categoryId: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        description?: string | null,
        color?: string | null,
        icon: IconType,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      price: number,
      uri?: string | null,
      barcodePictureUri?: string | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        startDate?: string | null,
        endDate?: string | null,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      eventPrice?: number | null,
      eventPercentage?: number | null,
      status?: ProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    count: number,
    deliveryId: string,
    delivery?:  {
      __typename: "Delivery",
      id: string,
      status: DeliveryStatus,
      orderDateTime: string,
      items: Array< string | null >,
      customer: string,
      orderDate: string,
      note: string,
      pointNumber: string,
      paymentType: PaymentType,
      cancelledReason?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDeliverySubscription = {
  onCreateDelivery?:  {
    __typename: "Delivery",
    id: string,
    status: DeliveryStatus,
    orderDateTime: string,
    items: Array< string | null >,
    customer: string,
    orderDate: string,
    note: string,
    pointNumber: string,
    paymentType: PaymentType,
    cancelledReason?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDeliverySubscription = {
  onUpdateDelivery?:  {
    __typename: "Delivery",
    id: string,
    status: DeliveryStatus,
    orderDateTime: string,
    items: Array< string | null >,
    customer: string,
    orderDate: string,
    note: string,
    pointNumber: string,
    paymentType: PaymentType,
    cancelledReason?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDeliverySubscription = {
  onDeleteDelivery?:  {
    __typename: "Delivery",
    id: string,
    status: DeliveryStatus,
    orderDateTime: string,
    items: Array< string | null >,
    customer: string,
    orderDate: string,
    note: string,
    pointNumber: string,
    paymentType: PaymentType,
    cancelledReason?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBannerSubscription = {
  onCreateBanner?:  {
    __typename: "Banner",
    id: string,
    uri: string,
    mobileUri: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBannerSubscription = {
  onUpdateBanner?:  {
    __typename: "Banner",
    id: string,
    uri: string,
    mobileUri: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBannerSubscription = {
  onDeleteBanner?:  {
    __typename: "Banner",
    id: string,
    uri: string,
    mobileUri: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};
