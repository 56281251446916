import { PaymentType, ProductStatus } from "../API"

// GraphQL
export interface Announcement {
  id: string
  title: string
  content: string
  date: string
}

export interface Product {
  id: string
  name: string
  price: number
  uri: string
  category: Category
  event: Event
  eventId: string
  eventPrice?: number
  eventPercentage?: number
  status?: ProductStatus
}

export interface Event {
  id: string
  name: string
  startDate: string
  endDate: string
  isActive: boolean
}

export interface Category {
  id: string
  name: string
  description?: string
  icon: IconType
  color: string
}

export enum IconType {
  MEAT = "MEAT",
  FISH = "FISH",
  BULK = "BULK",
  FRUIT = "FRUIT",
  VEGGIE = "VEGGIE",
  GRAIN = "GRAIN",
  DAIRY = "DAIRY",
  DRINK = "DRINK",
  CONSUMABLE = "CONSUMABLE",
  INSTANT = "INSTANT",
  FROZEN = "FROZEN",
  SAUCE = "SAUCE",
  DRIED = "DRIED",
  OIL = "OIL",
  UNDERWEAR = "UNDERWEAR",
  CLEANING = "CLEANING",
  COFFEE = "COFFEE",
  RAMEN = "RAMEN",
  BREAD = "BREAD",
  PASTRY = "PASTRY",
  DAILY = "DAILY",
}

export interface CartItem {
  id: string
  product: Product
  count: number
  delivery?: Delivery
}

export interface Delivery {
  id?: string
  items: CartItem[]
  customer: Customer
  orderDate: string
  status: DeliveryStatus
  note: string
  pointNumber: string
  paymentType: PaymentType
  cancelledReason?: string
}

export enum DeliveryStatus {
  ORDERED,
  OUT_FOR_DELIVERY,
  DELIVERED,
  CANCELLED,
}

export interface Customer {
  id?: string
  ip: string
  name: string
  address1: string
  address2: string
  phone: string
}

export interface Banner {
  id: string
  uri: string
  mobileUri: string
}

// Local
export interface MarginStyle {
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
  paddingTop?: number
  paddingBottom?: number
  paddingLeft?: number
  paddingRight?: number
}

export enum CountValue {
  INCREASE,
  DECREASE,
}

export interface LocalCart {
  id: number
  product: Product
  count: number
}

export enum DataType {
  PRODUCT,
  CATEGORY,
  ANNOUNCEMENT,
  CART_ITEM,
  DELIVERY_ITEM,
}

export interface StoreHour {
  id: string
  startTime: string
  endTime: string
  text1?: string
  text2?: string
  text3?: string
  text4?: string
  isForceDisabled: boolean
}
