import { SEARCH_BAR_DEBOUNCE_DELAY } from "../../../values"

export interface SearchBarState {
  value: string;
  fetching: boolean;
  fetchTimeout: NodeJS.Timeout | null;
}

export interface SearchBarAction {
  type: "CHANGE_VALUE";
  newValue: string;
}

export function searchBarReducerFunction(
  state: SearchBarState,
  action: SearchBarAction
): SearchBarState {
  switch (action.type) {
    case "CHANGE_VALUE":
      if(state.fetchTimeout !== null) clearTimeout(state.fetchTimeout)

      return {
        value: action.newValue,
        fetching: true,
        fetchTimeout: setTimeout(() => alert('패치 디바운스 구현'), SEARCH_BAR_DEBOUNCE_DELAY)
      }
  }
}
