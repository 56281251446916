/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement(
    $input: CreateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    createAnnouncement(input: $input, condition: $condition) {
      id
      title
      content
      date
      createdAt
      updatedAt
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement(
    $input: UpdateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    updateAnnouncement(input: $input, condition: $condition) {
      id
      title
      content
      date
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement(
    $input: DeleteAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    deleteAnnouncement(input: $input, condition: $condition) {
      id
      title
      content
      date
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      description
      color
      icon
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      description
      color
      icon
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      description
      color
      icon
      createdAt
      updatedAt
    }
  }
`;
export const createStoreHour = /* GraphQL */ `
  mutation CreateStoreHour(
    $input: CreateStoreHourInput!
    $condition: ModelStoreHourConditionInput
  ) {
    createStoreHour(input: $input, condition: $condition) {
      id
      startTime
      endTime
      text1
      text2
      text3
      text4
      isForceDisabled
      createdAt
      updatedAt
    }
  }
`;
export const updateStoreHour = /* GraphQL */ `
  mutation UpdateStoreHour(
    $input: UpdateStoreHourInput!
    $condition: ModelStoreHourConditionInput
  ) {
    updateStoreHour(input: $input, condition: $condition) {
      id
      startTime
      endTime
      text1
      text2
      text3
      text4
      isForceDisabled
      createdAt
      updatedAt
    }
  }
`;
export const deleteStoreHour = /* GraphQL */ `
  mutation DeleteStoreHour(
    $input: DeleteStoreHourInput!
    $condition: ModelStoreHourConditionInput
  ) {
    deleteStoreHour(input: $input, condition: $condition) {
      id
      startTime
      endTime
      text1
      text2
      text3
      text4
      isForceDisabled
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      categoryId
      category {
        id
        name
        description
        color
        icon
        createdAt
        updatedAt
      }
      name
      price
      uri
      barcodePictureUri
      eventId
      event {
        id
        name
        startDate
        endDate
        isActive
        createdAt
        updatedAt
      }
      eventPrice
      eventPercentage
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      categoryId
      category {
        id
        name
        description
        color
        icon
        createdAt
        updatedAt
      }
      name
      price
      uri
      barcodePictureUri
      eventId
      event {
        id
        name
        startDate
        endDate
        isActive
        createdAt
        updatedAt
      }
      eventPrice
      eventPercentage
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      categoryId
      category {
        id
        name
        description
        color
        icon
        createdAt
        updatedAt
      }
      name
      price
      uri
      barcodePictureUri
      eventId
      event {
        id
        name
        startDate
        endDate
        isActive
        createdAt
        updatedAt
      }
      eventPrice
      eventPercentage
      status
      createdAt
      updatedAt
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart(
    $input: CreateCartInput!
    $condition: ModelCartConditionInput
  ) {
    createCart(input: $input, condition: $condition) {
      id
      productId
      product {
        id
        categoryId
        category {
          id
          name
          description
          color
          icon
          createdAt
          updatedAt
        }
        name
        price
        uri
        barcodePictureUri
        eventId
        event {
          id
          name
          startDate
          endDate
          isActive
          createdAt
          updatedAt
        }
        eventPrice
        eventPercentage
        status
        createdAt
        updatedAt
      }
      count
      deliveryId
      delivery {
        id
        status
        orderDateTime
        items
        customer
        orderDate
        note
        pointNumber
        paymentType
        cancelledReason
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart(
    $input: UpdateCartInput!
    $condition: ModelCartConditionInput
  ) {
    updateCart(input: $input, condition: $condition) {
      id
      productId
      product {
        id
        categoryId
        category {
          id
          name
          description
          color
          icon
          createdAt
          updatedAt
        }
        name
        price
        uri
        barcodePictureUri
        eventId
        event {
          id
          name
          startDate
          endDate
          isActive
          createdAt
          updatedAt
        }
        eventPrice
        eventPercentage
        status
        createdAt
        updatedAt
      }
      count
      deliveryId
      delivery {
        id
        status
        orderDateTime
        items
        customer
        orderDate
        note
        pointNumber
        paymentType
        cancelledReason
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart(
    $input: DeleteCartInput!
    $condition: ModelCartConditionInput
  ) {
    deleteCart(input: $input, condition: $condition) {
      id
      productId
      product {
        id
        categoryId
        category {
          id
          name
          description
          color
          icon
          createdAt
          updatedAt
        }
        name
        price
        uri
        barcodePictureUri
        eventId
        event {
          id
          name
          startDate
          endDate
          isActive
          createdAt
          updatedAt
        }
        eventPrice
        eventPercentage
        status
        createdAt
        updatedAt
      }
      count
      deliveryId
      delivery {
        id
        status
        orderDateTime
        items
        customer
        orderDate
        note
        pointNumber
        paymentType
        cancelledReason
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDelivery = /* GraphQL */ `
  mutation CreateDelivery(
    $input: CreateDeliveryInput!
    $condition: ModelDeliveryConditionInput
  ) {
    createDelivery(input: $input, condition: $condition) {
      id
      status
      orderDateTime
      items
      customer
      orderDate
      note
      pointNumber
      paymentType
      cancelledReason
      createdAt
      updatedAt
    }
  }
`;
export const updateDelivery = /* GraphQL */ `
  mutation UpdateDelivery(
    $input: UpdateDeliveryInput!
    $condition: ModelDeliveryConditionInput
  ) {
    updateDelivery(input: $input, condition: $condition) {
      id
      status
      orderDateTime
      items
      customer
      orderDate
      note
      pointNumber
      paymentType
      cancelledReason
      createdAt
      updatedAt
    }
  }
`;
export const deleteDelivery = /* GraphQL */ `
  mutation DeleteDelivery(
    $input: DeleteDeliveryInput!
    $condition: ModelDeliveryConditionInput
  ) {
    deleteDelivery(input: $input, condition: $condition) {
      id
      status
      orderDateTime
      items
      customer
      orderDate
      note
      pointNumber
      paymentType
      cancelledReason
      createdAt
      updatedAt
    }
  }
`;
export const createBanner = /* GraphQL */ `
  mutation CreateBanner(
    $input: CreateBannerInput!
    $condition: ModelBannerConditionInput
  ) {
    createBanner(input: $input, condition: $condition) {
      id
      uri
      mobileUri
      createdAt
      updatedAt
    }
  }
`;
export const updateBanner = /* GraphQL */ `
  mutation UpdateBanner(
    $input: UpdateBannerInput!
    $condition: ModelBannerConditionInput
  ) {
    updateBanner(input: $input, condition: $condition) {
      id
      uri
      mobileUri
      createdAt
      updatedAt
    }
  }
`;
export const deleteBanner = /* GraphQL */ `
  mutation DeleteBanner(
    $input: DeleteBannerInput!
    $condition: ModelBannerConditionInput
  ) {
    deleteBanner(input: $input, condition: $condition) {
      id
      uri
      mobileUri
      createdAt
      updatedAt
    }
  }
`;
