import React, { useReducer } from "react"
import { DEFAULT_SEARCH_BAR_STATES } from "../defaultValues"
import { searchBarReducerFunction } from "../functions/searchBarReducer"

const useSearchBarReducer = () => {
  const [searchBarStates, searchBarDispatch] = useReducer(
    searchBarReducerFunction,
    DEFAULT_SEARCH_BAR_STATES
  )

  function setValue(newValue: string) {
    searchBarDispatch({
      type: "CHANGE_VALUE",
      newValue: newValue
    })
  }

  return {
    states: searchBarStates,
    setValue
  }
}

export default useSearchBarReducer