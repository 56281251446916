/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAnnouncement = /* GraphQL */ `
  query GetAnnouncement($id: ID!, $date: AWSDateTime!) {
    getAnnouncement(id: $id, date: $date) {
      id
      title
      content
      date
      createdAt
      updatedAt
    }
  }
`;
export const listAnnouncements = /* GraphQL */ `
  query ListAnnouncements(
    $id: ID
    $date: ModelStringKeyConditionInput
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAnnouncements(
      id: $id
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        content
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      startDate
      endDate
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        startDate
        endDate
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      description
      color
      icon
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        color
        icon
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStoreHour = /* GraphQL */ `
  query GetStoreHour($id: ID!) {
    getStoreHour(id: $id) {
      id
      startTime
      endTime
      text1
      text2
      text3
      text4
      isForceDisabled
      createdAt
      updatedAt
    }
  }
`;
export const listStoreHours = /* GraphQL */ `
  query ListStoreHours(
    $filter: ModelStoreHourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStoreHours(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startTime
        endTime
        text1
        text2
        text3
        text4
        isForceDisabled
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      categoryId
      category {
        id
        name
        description
        color
        icon
        createdAt
        updatedAt
      }
      name
      price
      uri
      barcodePictureUri
      eventId
      event {
        id
        name
        startDate
        endDate
        isActive
        createdAt
        updatedAt
      }
      eventPrice
      eventPercentage
      status
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryId
        category {
          id
          name
          description
          color
          icon
          createdAt
          updatedAt
        }
        name
        price
        uri
        barcodePictureUri
        eventId
        event {
          id
          name
          startDate
          endDate
          isActive
          createdAt
          updatedAt
        }
        eventPrice
        eventPercentage
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCart = /* GraphQL */ `
  query GetCart($id: ID!) {
    getCart(id: $id) {
      id
      productId
      product {
        id
        categoryId
        category {
          id
          name
          description
          color
          icon
          createdAt
          updatedAt
        }
        name
        price
        uri
        barcodePictureUri
        eventId
        event {
          id
          name
          startDate
          endDate
          isActive
          createdAt
          updatedAt
        }
        eventPrice
        eventPercentage
        status
        createdAt
        updatedAt
      }
      count
      deliveryId
      delivery {
        id
        status
        orderDateTime
        items
        customer
        orderDate
        note
        pointNumber
        paymentType
        cancelledReason
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCarts = /* GraphQL */ `
  query ListCarts(
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        product {
          id
          categoryId
          category {
            id
            name
            description
            color
            icon
            createdAt
            updatedAt
          }
          name
          price
          uri
          barcodePictureUri
          eventId
          event {
            id
            name
            startDate
            endDate
            isActive
            createdAt
            updatedAt
          }
          eventPrice
          eventPercentage
          status
          createdAt
          updatedAt
        }
        count
        deliveryId
        delivery {
          id
          status
          orderDateTime
          items
          customer
          orderDate
          note
          pointNumber
          paymentType
          cancelledReason
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDelivery = /* GraphQL */ `
  query GetDelivery($id: ID!) {
    getDelivery(id: $id) {
      id
      status
      orderDateTime
      items
      customer
      orderDate
      note
      pointNumber
      paymentType
      cancelledReason
      createdAt
      updatedAt
    }
  }
`;
export const listDeliverys = /* GraphQL */ `
  query ListDeliverys(
    $filter: ModelDeliveryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliverys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        orderDateTime
        items
        customer
        orderDate
        note
        pointNumber
        paymentType
        cancelledReason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBanner = /* GraphQL */ `
  query GetBanner($id: ID!) {
    getBanner(id: $id) {
      id
      uri
      mobileUri
      createdAt
      updatedAt
    }
  }
`;
export const listBanners = /* GraphQL */ `
  query ListBanners(
    $filter: ModelBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uri
        mobileUri
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsByEvent = /* GraphQL */ `
  query ProductsByEvent(
    $eventId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByEvent(
      eventId: $eventId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        category {
          id
          name
          description
          color
          icon
          createdAt
          updatedAt
        }
        name
        price
        uri
        barcodePictureUri
        eventId
        event {
          id
          name
          startDate
          endDate
          isActive
          createdAt
          updatedAt
        }
        eventPrice
        eventPercentage
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsByCategory = /* GraphQL */ `
  query ProductsByCategory(
    $categoryId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByCategory(
      categoryId: $categoryId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        category {
          id
          name
          description
          color
          icon
          createdAt
          updatedAt
        }
        name
        price
        uri
        barcodePictureUri
        eventId
        event {
          id
          name
          startDate
          endDate
          isActive
          createdAt
          updatedAt
        }
        eventPrice
        eventPercentage
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const deliverysByStatus = /* GraphQL */ `
  query DeliverysByStatus(
    $status: DeliveryStatus
    $orderDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliverysByStatus(
      status: $status
      orderDateTime: $orderDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        orderDateTime
        items
        customer
        orderDate
        note
        pointNumber
        paymentType
        cancelledReason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const deliverysByOrderDate = /* GraphQL */ `
  query DeliverysByOrderDate(
    $orderDate: String
    $orderDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliverysByOrderDate(
      orderDate: $orderDate
      orderDateTime: $orderDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        orderDateTime
        items
        customer
        orderDate
        note
        pointNumber
        paymentType
        cancelledReason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
