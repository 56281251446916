import React, { useState } from "react"
import { CartItem } from "../../../types"
import { navigate } from 'gatsby';

const useAddConformModal = () => {
  const [isModalOn, setIsModalOn] = useState<boolean>(false)
  const [addedCartItem, setAddedCartItem] = useState<CartItem>()

  function showModal(cartItem: CartItem) {
    setIsModalOn(true)
    setAddedCartItem(cartItem)
  }

  function continueShopping() {
    setIsModalOn(false)
  }

  function navigateToCart() {
    setIsModalOn(false)
    navigate("/ShoppingCart")
  }

  return {
    isModalOn,
    addedCartItem,
    showModal,
    continueShopping,
    navigateToCart
  }
}

export default useAddConformModal