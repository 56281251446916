import React, { useEffect, useState } from "react"
import { apiGetStoreHour } from "../../../functions/api/storehour"
import { StoreHour } from "../../../types"
import moment from "moment"

const useStoreHour = () => {
  const [storeHour, setStoreHour] = useState<StoreHour>()

  useEffect(() => {
    ;(async () => {
      const response = await apiGetStoreHour()
      if (response) setStoreHour(response)
    })()
  }, [])

  // useEffect(() => {
  //   if (storeHour && storeHour.text4 && storeHour.text4 != "") {
  //     alert(storeHour.text4)
  //   }
  // }, [storeHour])

  function isDeliverable() {
    const now = moment().format("HH:mm:SS")
    if (storeHour?.isForceDisabled) {
      return false
    } else {
      return storeHour?.startTime! <= now && now <= storeHour?.endTime!
    }
  }

  return {
    storeHour,
    isDeliverable,
  }
}

export default useStoreHour
