// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-announcements-index-tsx": () => import("./../../../src/pages/Announcements/index.tsx" /* webpackChunkName: "component---src-pages-announcements-index-tsx" */),
  "component---src-pages-category-product-index-tsx": () => import("./../../../src/pages/CategoryProduct/index.tsx" /* webpackChunkName: "component---src-pages-category-product-index-tsx" */),
  "component---src-pages-delivery-component-cart-item-index-tsx": () => import("./../../../src/pages/Delivery/component/CartItem/index.tsx" /* webpackChunkName: "component---src-pages-delivery-component-cart-item-index-tsx" */),
  "component---src-pages-delivery-component-delivery-item-index-tsx": () => import("./../../../src/pages/Delivery/component/DeliveryItem/index.tsx" /* webpackChunkName: "component---src-pages-delivery-component-delivery-item-index-tsx" */),
  "component---src-pages-delivery-index-tsx": () => import("./../../../src/pages/Delivery/index.tsx" /* webpackChunkName: "component---src-pages-delivery-index-tsx" */),
  "component---src-pages-event-product-index-tsx": () => import("./../../../src/pages/EventProduct/index.tsx" /* webpackChunkName: "component---src-pages-event-product-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-result-index-tsx": () => import("./../../../src/pages/SearchResult/index.tsx" /* webpackChunkName: "component---src-pages-search-result-index-tsx" */),
  "component---src-pages-shopping-cart-index-tsx": () => import("./../../../src/pages/ShoppingCart/index.tsx" /* webpackChunkName: "component---src-pages-shopping-cart-index-tsx" */)
}

