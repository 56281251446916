import { useEffect, useState } from "react"
import { apiListCategorys } from "../../../functions/api/category"
import { apiGetProductsByCategory } from "../../../functions/api/product"
import { Category, Product } from "../../../types"

export interface PickedCategory {
  category: Category
  products: Product[]
}

const useCategory = () => {
  const [categories, setCategories] = useState<Category[]>()
  const [randomlyPickedProducts, setRandomlyPickedProducts] = useState<PickedCategory[]>([])
  const PICKED_PRODUCT_COUNT = 8

  useEffect(() => {
    ;(async () => {
      const categoryList = await loadCategories()

      if (categoryList) {
        let selectedIndexSet: number[] = []
        let categoryCount = categoryList.length
        let CATEGORY_SELECT_COUNT = categoryCount < 3 ? categoryCount : 3
        let cap = 3000

        while (selectedIndexSet.length < CATEGORY_SELECT_COUNT) {
          const selectedIndex = Math.floor(Math.random() * categoryCount)
          if (!selectedIndexSet.includes(selectedIndex)) {
            selectedIndexSet.push(selectedIndex)
          }
          cap -= 1
          if (cap <= 0) break
        }

        for (let i = 0; i < selectedIndexSet.length; i++) {
          let picked: any = { category: categoryList[selectedIndexSet[i]] }
          const items = await apiGetProductsByCategory(
            picked.category.id,
            undefined,
            PICKED_PRODUCT_COUNT
          )
          if (items && items.items.length === PICKED_PRODUCT_COUNT) {
            picked.products = items.items
            randomlyPickedProducts.push(picked)
          }
        }
        setRandomlyPickedProducts([...randomlyPickedProducts])
      }
    })()

    async function loadCategories() {
      const categoryList = await apiListCategorys()
      setCategories(categoryList)

      return categoryList
    }
  }, [])

  return {
    data: categories,
    randomlyPickedProducts,
  }
}

export default useCategory
