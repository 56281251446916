import { DeliveryStatus } from "../../API";
import { Customer } from "../../types";
import { PaymentType } from '../../API';
import { DeliveryState } from "./functions/deliveryReducer";
import { SearchBarState } from "./functions/searchBarReducer";

export const DEFAULT_SEARCH_BAR_STATES: SearchBarState = {
  value: "",
  fetching: false,
  fetchTimeout: null,
}

const DEFAULT_CUSTOMER: Customer = {
  ip: "",
  name: "",
  address1: "",
  address2: "",
  phone: "",
}

export const DEFAULT_DELIVERY_STATES: DeliveryState = {
  delivery: {
    orderDate: new Date().toISOString(),
    items: [],
    status: DeliveryStatus.ORDERED,
    customer: DEFAULT_CUSTOMER,
    note: "",
    pointNumber: "",
    paymentType: PaymentType.CASH
  },
}

