import { API, graphqlOperation } from "aws-amplify"

import { handleError, handleSuccess } from ".."
import {
  CreateCategoryInput,
  CreateCategoryMutation,
  DeleteCategoryInput,
  DeleteCategoryMutation,
  GetCategoryQuery,
  ListCategorysQuery,
  ModelCategoryConditionInput,
  ModelCategoryFilterInput,
  UpdateCategoryInput,
  UpdateCategoryMutation,
} from "../../API"

import * as mutations from "../../graphql/mutations"
import * as queries from "../../graphql/queries"

import { Category, IconType } from "../../types"

export const apiCreateCategory = async (
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(mutations.createCategory, { input, condition })
    )) as { data: CreateCategoryMutation }
    handleSuccess(response)
    return (response.data.createCategory as unknown) as Category
  } catch (error) {
    handleError(error)
  }
}

export const apiUpdateCategory = async (
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(mutations.updateCategory, { input, condition })
    )) as { data: UpdateCategoryMutation }
    handleSuccess(response)
    return (response.data.updateCategory as unknown) as Category
  } catch (error) {
    handleError(error)
  }
}

export const apiDeleteCategory = async (
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(mutations.deleteCategory, { input, condition })
    )) as { data: DeleteCategoryMutation }
    handleSuccess(response)
    return (response.data.deleteCategory as unknown) as Category
  } catch (error) {
    handleError(error)
  }
}

const convertIconToType = (icon?: string) => {
  switch (icon) {
    case "MEAT":
      return IconType.MEAT
    case "FISH":
      return IconType.FISH
    case "BULK":
      return IconType.BULK
    case "FRUIT":
      return IconType.FRUIT
    case "VEGGIE":
      return IconType.VEGGIE
    case "GRAIN":
      return IconType.GRAIN
    case "DAIRY":
      return IconType.DAIRY
    case "DRINK":
      return IconType.DRINK
    case "CONSUMABLE":
      return IconType.CONSUMABLE
    case "INSTANT":
      return IconType.INSTANT
    case "FROZEN":
      return IconType.FROZEN
    case "SAUCE":
      return IconType.SAUCE
    case "DRIED":
      return IconType.DRIED
    case "OIL":
      return IconType.OIL
    case "UNDERWEAR":
      return IconType.UNDERWEAR
    case "CLEANING":
      return IconType.CLEANING
    case "COFFEE":
      return IconType.COFFEE
    case "RAMEN":
      return IconType.RAMEN
    case "BREAD":
      return IconType.BREAD
    case "PASTRY":
      return IconType.PASTRY
    case "DAILY":
      return IconType.DAILY
    default:
      return null
  }
}

export const apiGetCategory = async (id: String) => {
  try {
    const response = (await API.graphql(graphqlOperation(queries.getCategory, { id }))) as {
      data: GetCategoryQuery
    }
    handleSuccess(response)
    return ({
      ...response.data.getCategory,
      icon: convertIconToType(response.data.getCategory?.icon),
    } as unknown) as Category
  } catch (error) {
    handleError(error)
  }
}

export const apiListCategorys = async (
  filter?: ModelCategoryFilterInput,
  limit?: Number,
  nextToken?: String
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(queries.listCategorys, { filter, limit, nextToken })
    )) as { data: ListCategorysQuery }
    handleSuccess(response)
    return (response.data.listCategorys?.items?.map(item => ({
      ...item,
      icon: convertIconToType(item?.icon),
    })) as unknown) as Category[]
  } catch (error) {
    handleError(error)
  }
}
