// gatsby-browser.js
import React from 'react';
import GlobalContextProvider from './src/contexts/GlobalContext';
import "./src/components/organism/Layout/layout.css"

export const wrapRootElement = ({ element }) => {
  return (
    <GlobalContextProvider>
      {element}
    </GlobalContextProvider>
  );
}