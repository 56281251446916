import { FooterButtonProps } from "../components/atom/FooterButton"

// General
export const DEBUG_MODE = false
export enum PAGE {
  HOME = "/",
  ANNOUNCEMENT = "/Announcements",
  EVENT_PRODUCT = "/EventProduct",
  SEARCH_RESULT = "/SearchResult",
  CATEGORY_PRODUCT = "/CategoryProduct",
  SHOPPING_CART = "/ShoppingCart",
  DELIVERY = "/Delivery",
}
export const CATEGORY_ACCENT_COLOR = "#574A7A"
export const TEST_ICON_STYLE = {
  width: 20,
  height: 20,
  margin: 0,
}

// Header
export const HEADER_TITLE = "효자콜마트청과"
export const HEADER_COLOR = "#ffffff"

// Footer
export const FOOTER_TITLE = "효자콜마트청과"
export const FOOTER_FIRST_ADDRESS = "경상북도 포항시 남구 효자동 574-1"
export const FOOTER_SECOND_ADDRESS = "효자 SK뷰 1차 정문 앞"
export const FOOTER_PHONE_NUMBER = "054-272-8890"
export const FOOTER_BUTTON_LIST: FooterButtonProps[] = [
  {
    name: "공지사항",
    destination: PAGE.ANNOUNCEMENT,
  },
  {
    name: "장바구니",
    destination: PAGE.SHOPPING_CART,
  },
  {
    name: "배달현황",
    destination: PAGE.DELIVERY,
  },
]

// Home
export const EVENT_PRODUCT_SECTION_TITLE = "행사 제품"
export const CATEGORIES_SECTION_TITLE = "카테고리별 상품"
export const ANNOUNCEMENT_SECTION_TITLE = "공지"
export const SEE_EVENT_PRODUCT_BUTTON = "더보기"
export const EVENT_ITEM_LIST_LIMIT = 6
export const ANNOUNCEMENT_LIST_LIMIT = undefined

//Announcement
export const NO_ANNOUNCEMENT = "공지사항이 없습니다."

// SearchBar
export const SEARCH_BAR_HINT = "검색"
export const SEARCH_BAR_DEBOUNCE_DELAY = 500

// Product Item
export const ADD_TO_CART_TEXT = "장바구니에 담기"

// Cart
export const CART_SECTION_TITLE = "장바구니"
export const DELIVERY_SECTION_TITLE = "주문하기"
export const LACK_NAME_TEXT = "이름을 입력해주세요"
export const TOO_LONG_NAME_TEXT = "이름이 너무 깁니다"
export const LACK_ADDRESS_TEXT = "주소를 입력해주세요"
export const TOO_LONG_ADDRESS_TEXT = "주소가 너무 깁니다"
export const LACK_PHONE_TEXT = "휴대폰 번호를 입력해주세요"
export const INVALID_PHONE_TEXT = "올바른 휴대폰 번호를 입력해주세요"
export const INVALID_POINT_NUMBER_TEXT = "올바른 포인트 번호를 입력해주세요(전화번호 뒷 4자리)"
export const TOO_LONG_MESSAGE_TEXT = "메모가 너무 깁니다"
export const NOT_ENOUGH_PRICE = "최소주문 금액 20,000원(정육류 제외) 중 "

// Delivery
export const DELIVERY_PAGE_TITLE = "주문내역"

// Error Text
export const LACKS_NEW_CART = "subjectProduct field is required in NEW_CART_ITEM type"
export const LACKS_EDIT_PRODUCT = "subjectProduct field is required in EDIT_PRODUCT type"
export const LACKS_DELETE_PRODUCT = "subjectProduct field is required in DELETE_PRODUCT type"
export const LACKS_EDIT_CUSTOMER = "customer field is required in EDIT_CUSTOMER type"

// Misc
export const CURRENCY_WON = "원"
export const MEDIA_QUERY = "(min-width:1024px)"

export const DEFAULT_EVENT_ID = "a78578e8-58df-4cd0-a8c9-61f11e0d3662"
export const ACTIVE_EVENT_ID = "a78578e8-58df-4cd0-a8c9-61f11e0d3663"

export const ITEM_LIMIT = 20
export const STORE_HOUR_ID = "85ab8e24-254d-48c1-94cf-1df6af996526"
export const PRIVACY_POLICY = `콜마트 개인정보처리약관
제1조 (총칙)

회사(효자OK콜마트)는 고객님의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.

회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며,
개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.

회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.

ο 본 방침은 : 2021 년 03 월 01 일 부터 시행됩니다.

 
제2조 (수집하는 개인정보 항목)
회사는 주문, 배송 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
ο 수집항목 : 이름, 주소, 휴대전화번호, 접속 IP
ο 개인정보 수집방법 : 배송 주문 접수시

 
제 3조 개인정보의 보유 및 이용기간
회사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체없이 파기합니다.
 
제 4조 개인정보의 파기절차 및 방법
회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.

ο 파기절차 : 회원님이 주문을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져 (종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 (보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다. 　
별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.

ο 파기방법 - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
 
제5조 개인정보 제공
회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.

ο 이용자들이 사전에 동의한 경우 ο 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
 
제 6조 수집한 개인정보의 위탁
회사는 고객님의 동의없이 고객님의 정보를 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.
 
제 7조 개인정보에 관한 민원서비스
회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.

ο 이용자들이 사전에 동의한 경우 ο 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
 
제 9조 이용자 및 법정대리인의 권리와 그 행사방법
회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.

- 전화번호 : 054-272-8890
- 이메일 : playofn5044@naver.com

귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

1. 개인분쟁조정위원회 (www.1336.or.kr/1336)
2. 정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4)
3. 대검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr/02-3480-3600)
4. 경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)`

export const DELIVERY_LOCAL_KEY = "DELIVERY_LOCAL_KEY"

export const CLOUD_FRONT_URI = "https://d1j08yi80ppgv2.cloudfront.net"

export const ANNOUNCEMENT_ID = "b3adfa3a-359b-4a5e-aef8-5f14931963be"
