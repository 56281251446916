import { API, graphqlOperation } from "aws-amplify"

import { handleError, handleSuccess } from ".."
import {
  CreateProductInput,
  CreateProductMutation,
  DeleteProductInput,
  DeleteProductMutation,
  GetProductQuery,
  ListProductsQuery,
  ModelProductConditionInput,
  ModelProductFilterInput,
  ProductsByCategoryQuery,
  ProductsByEventQuery,
  UpdateProductInput,
  UpdateProductMutation,
  ModelStringKeyConditionInput,
} from "../../API"

import * as mutations from "../../graphql/mutations"
import * as queries from "../../graphql/queries"

import { Product } from "../../types"

export const apiCreateProduct = async (
  input: CreateProductInput,
  condition?: ModelProductConditionInput
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(mutations.createProduct, { input, condition })
    )) as { data: CreateProductMutation }
    handleSuccess(response)
    return (response.data.createProduct as unknown) as Product
  } catch (error) {
    handleError(error)
  }
}

export const apiUpdateProduct = async (
  input: UpdateProductInput,
  condition?: ModelProductConditionInput
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(mutations.updateProduct, { input, condition })
    )) as { data: UpdateProductMutation }
    handleSuccess(response)
    return (response.data.updateProduct as unknown) as Product
  } catch (error) {
    handleError(error)
  }
}

export const apiDeleteProduct = async (
  input: DeleteProductInput,
  condition?: ModelProductConditionInput
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(mutations.deleteProduct, { input, condition })
    )) as { data: DeleteProductMutation }
    handleSuccess(response)
    return (response.data.deleteProduct as unknown) as Product
  } catch (error) {
    handleError(error)
  }
}

export const apiGetProduct = async (id: String) => {
  try {
    const response = (await API.graphql(graphqlOperation(queries.getProduct, { id }))) as {
      data: GetProductQuery
    }
    handleSuccess(response)
    return (response.data.getProduct as unknown) as Product
  } catch (error) {
    handleError(error)
  }
}

export const apiListProducts = async (
  filter?: ModelProductFilterInput,
  limit?: Number,
  nextToken?: string
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(queries.listProducts, { filter, limit, nextToken })
    )) as { data: ListProductsQuery }
    handleSuccess(response)
    return {
      items: (response.data.listProducts?.items as unknown) as Product[],
      nextToken: response.data.listProducts?.nextToken,
    }
  } catch (error) {
    handleError(error)
  }
}

export const apiGetProductsByCategory = async (
  categoryId: string,
  filter?: ModelProductFilterInput,
  limit?: Number,
  nextToken?: string
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(queries.productsByCategory, { categoryId, filter, limit, nextToken })
    )) as { data: ProductsByCategoryQuery }
    handleSuccess(response)
    return {
      items: (response.data.productsByCategory?.items as unknown) as Product[],
      nextToken: response.data.productsByCategory?.nextToken,
    }
  } catch (error) {
    handleError(error)
  }
}

export const apiGetProductsByEvent = async (
  eventId: string,
  filter?: ModelProductFilterInput,
  limit?: Number,
  nextToken?: string
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(queries.productsByEvent, { eventId, filter, limit, nextToken })
    )) as { data: ProductsByEventQuery }
    handleSuccess(response)
    return {
      items: (response.data.productsByEvent?.items as unknown) as Product[],
      nextToken: response.data.productsByEvent?.nextToken,
    }
  } catch (error) {
    handleError(error)
  }
}
