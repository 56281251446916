import { Announcement, Category, IconType } from "../types"
import { DEBUG_MODE } from "../values"

export function handleError(error: Error) {
  alert("에러가 발생했습니다. 불편을 드려 죄송합니다. 054-272-8890으로 연락주시면 조속히 해결해드리겠습니다. ")
  //console.log("Error from: ", type)
  console.error(error)
}

export function handleSuccess(response: object) {
  if (DEBUG_MODE) console.dir(response)
}

export function numberWithCommas(x: number) {
  return (x + "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function getCategoryImage(category: Category, isSelected: boolean) {
  switch (category?.icon) {
    case IconType.MEAT:
      return isSelected
        ? require("../images/categories/meat-white.svg")
        : require("../images/categories/meat.svg")
    case IconType.FISH:
      return isSelected
        ? require("../images/categories/fish-white.svg")
        : require("../images/categories/fish.svg")
    case IconType.BULK:
      return require("../images/categories/업소용.jpeg")
    case IconType.FRUIT:
      return require("../images/categories/fruit.svg")
    case IconType.VEGGIE:
      return isSelected
        ? require("../images/categories/veggie-white.svg")
        : require("../images/categories/veggie.svg")
    case IconType.GRAIN:
      return require("../images/categories/쌀.jpeg")
    case IconType.DAIRY:
      return require("../images/categories/계란유제품.jpg")
    case IconType.DRINK:
      return require("../images/categories/생수음료.jpg")
    case IconType.CONSUMABLE:
      return require("../images/categories/제지류.jpeg")
    case IconType.INSTANT:
      return require("../images/categories/통조림.jpeg")
    case IconType.FROZEN:
      return require("../images/categories/냉장신선식품.jpg")
    case IconType.SAUCE:
      return require("../images/categories/양념소스.jpg")
    case IconType.DRIED:
      return require("../images/categories/김건어물.jpg")
    case IconType.OIL:
      return require("../images/categories/식용유밀가루.jpg")
    case IconType.UNDERWEAR:
      return isSelected
        ? require("../images/categories/Underwear_white.svg")
        : require("../images/categories/Underwear.svg")
    case IconType.CLEANING:
      return require("../images/categories/세제.jpg")
    case IconType.COFFEE:
      return require("../images/categories/커피시리얼.jpg")
    case IconType.RAMEN:
      return require("../images/categories/라면면류.png")
    case IconType.BREAD:
      return require("../images/categories/파인애플.jpg")
    case IconType.PASTRY:
      return require("../images/categories/고추장간장.png")
    case IconType.DAILY:
      return require("../images/categories/생활잡화.jpg")
    default:
      return require("../images/test-image.svg")
  }
}
