import { API, graphqlOperation } from 'aws-amplify';

import { handleError, handleSuccess } from "..";
import { CreateEventMutation, CreateEventInput, DeleteEventMutation, DeleteEventInput, GetEventQuery, ModelEventConditionInput, ModelEventFilterInput, UpdateEventMutation, UpdateEventInput, ListEventsQuery } from "../../API";

import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

import { Event } from '../../types';

export const apiCreateEvent = async (input: CreateEventInput, condition?: ModelEventConditionInput) => {
  try {
    const response = await API.graphql(graphqlOperation(mutations.createEvent, { input, condition })) as { data: CreateEventMutation };
    handleSuccess(response);
    return response.data.createEvent as unknown as Event;
  } catch (error) {
    handleError(error);
  }
}

export const apiUpdateEvent = async (input: UpdateEventInput, condition?: ModelEventConditionInput) => {
  try {
    const response = await API.graphql(graphqlOperation(mutations.updateEvent, { input, condition })) as { data: UpdateEventMutation };
    handleSuccess(response);
    return response.data.updateEvent as unknown as Event;
  } catch (error) {
    handleError(error);
  }
}

export const apiDeleteEvent = async (input: DeleteEventInput, condition?: ModelEventConditionInput) => {
  try {
    const response = await API.graphql(graphqlOperation(mutations.deleteEvent, { input, condition })) as { data: DeleteEventMutation };
    handleSuccess(response);
    return response.data.deleteEvent as unknown as Event;
  } catch (error) {
    handleError(error);
  }
}

export const apiGetEvent = async (id: String) => {
  try {
    const response = await API.graphql(graphqlOperation(queries.getEvent, { id })) as { data: GetEventQuery };
    handleSuccess(response);
    return response.data.getEvent as unknown as Event;
  } catch (error) {
    handleError(error);
  }
}

export const apiListEvents = async (filter?: ModelEventFilterInput, limit?: Number, nextToken?: String) => {
  try {
    const response = await API.graphql(graphqlOperation(queries.listEvents, { filter, limit, nextToken })) as { data: ListEventsQuery };
    handleSuccess(response);
    return response.data.listEvents?.items as unknown as Event[];
  } catch (error) {
    handleError(error);
  }
}