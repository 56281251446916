import React, { useEffect, useState } from "react"
import { apiGetEvent } from "../../../functions/api/event"
import { Event } from "../../../types"
import { ACTIVE_EVENT_ID } from "../../../values"
import moment from "moment"

const useEvent = () => {
  const [event, setEvent] = useState<Event>()

  useEffect(() => {
    ;(async() => {
      const response = await apiGetEvent(ACTIVE_EVENT_ID)
      if(response) {
        setEvent(response)
      }
    })()
  }, [])

  function getIsEventActivated() {
    if (event) {
      const currentDate = new Date()
      const startDate = new Date(event.startDate)
      const endDate = new Date(event.endDate)
      const isActive = event.isActive

      return isActive && currentDate > startDate && currentDate < endDate
    }

    return false
  }

  function getEventTermText() {
    const start = moment(event?.startDate)
    const end = moment(event?.endDate)

    if (start.month() === end.month() && start.date() === end.date()) {
      return "오늘 하루만"
    } else {
      return (
        moment(event?.startDate).format("MM/DD") + " - " + moment(event?.endDate).format("MM/DD")
      )
    }
  }

  return {
    data: event,
    getIsEventActivated,
    getEventTermText
  }
}

export default useEvent