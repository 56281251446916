import { useEffect, useState } from "react"
import { handleError } from "../../../functions"
import { apiGetProductsByCategory, apiListProducts } from "../../../functions/api/product"
import { Category, Product } from "../../../types"
import { ITEM_LIMIT } from "../../../values"

const useCategoryProduct = () => {
  const [category, setCategory] = useState<Category>()
  const [categoryProducts, setCategoryProducts] = useState<Product[]>([])
  const [shouldScroll, setShouldScroll] = useState<boolean>(true)
  const [nextToken, setNextToken] = useState<string | null | undefined>(null)

  const onScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 400) {
      if (nextToken) {
        setShouldScroll(true)
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [shouldScroll, nextToken])

  const changeCategory = (category: Category) => {
    setCategoryProducts([])
    setShouldScroll(true)
    setNextToken(null)
    setCategory(category)
  }

  useEffect(() => {
    ;(async () => {
      if (category && category.id && shouldScroll) {
        console.dir(category)

        try {
          const response = await apiGetProductsByCategory(
            category.id,
            undefined,
            ITEM_LIMIT,
            nextToken || undefined
          )
          
          if(nextToken===null) {
            window.scrollTo(0, 1000)
          }

          if (response) {
            setCategoryProducts(p => {
              return p.concat(response!.items).filter(i => i.category.id == category.id)
            })
            setNextToken(response!.nextToken)
            setShouldScroll(false)
          }
        } catch (error) {
          handleError(error)
        }
      }
    })()
  }, [category, shouldScroll])

  return {
    categoryProducts,
    category,
    setCategory: changeCategory,
  }
}

export default useCategoryProduct
